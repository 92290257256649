import axios from "axios";

/* axios.create({
  baseURL: 'http://localhost:3000/api/v1',
  timeout: 5000,
  headers: {
    'X-Auth-Token': 'f2b6637ddf355a476918940289c0be016a4fe99e3b69c83d',
    'Content-Type': 'application/json'
  }
}) */

export default {
  getProducts() {
    return axios
      .get(process.env.VUE_APP_STRAPI_URL + "/products")
      .then((response) => {
        return response.data;
      });
  },
  getProduct(id) {
    return axios
      .get(process.env.VUE_APP_STRAPI_URL + "/products/" + id)
      .then((response) => {
        console.log(response.data);
        return response.data;
      });
  },
};
